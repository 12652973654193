<template>
  <section>
    <v-container>
      <v-row>
        <v-col cols="12" offset-sm="2" sm="8" offset-md="3" md="6">
          <v-card elevation="5">
            <v-card-text class="text-center pt-16 px-2">
              <v-row class="mb-8" justify="center">
                <v-col cols="12" sm="auto" class="mt-n5">
                  <span class="d-inline">
                    <img src="/images/open-quote.svg" alt="" />
                  </span>
                </v-col>

                <v-col cols="8">
                  <div class="text-medium-emphasis">
                    <p class="d-inline-block">
                      I am extremely impressed with the transformative impact
                      Users Driven had on my website, danserbanoiu.com. Their
                      expert feedback led to a significant boost in user
                      engagement and conversions. By recommending a prominently
                      placed contact form and integrating Calendly for free
                      initial consultations, they streamlined the user journey
                      and increased appointment bookings. Their keen eye for
                      detail helped fix hidden texts in dark mode and enhanced
                      the visibility of client logos, improving the overall user
                      experience and professionalism of the site. Users Driven's
                      marketing insights, including a more memorable domain name
                      and strategic design improvements, have been invaluable.
                      Their comprehensive and practical suggestions have
                      elevated my website's performance. I highly recommend
                      Users Driven for anyone looking to optimize their online
                      presence effectively.
                    </p>
                  </div>
                </v-col>

                <v-col cols="12" sm="auto" align-self="end" class="">
                  <span class="d-inline">
                    <img src="/images/close-quote.svg" alt="" />
                  </span>
                </v-col>
              </v-row>

              <div class="mb-5">
                <v-avatar
                  rounded="circle"
                  size="68"
                  image="https://png.pngtree.com/png-clipart/20190924/original/pngtree-user-vector-avatar-png-image_4830521.jpg"
                />
              </div>

              <v-rating
                model-value="5"
                active-color="yellow-accent-4"
                color="white"
                size="18"
                half-increments
                hover
              />

              <p class="customer-name">Dan Ser</p>
              <p class="customer-description mb-15">Client</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped>
:deep(.v-img__img) {
  border-radius: 50%;
}

.customer-name {
  color: #263238;
  font-size: 16px;
  font-weight: 600;
}

.customer-description {
  color: #263238;
}
</style>
